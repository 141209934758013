import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../tokens'

function UserProfileCard({ profileImg, className, children, ...props }) {
  return (
    <ProfileCard
      {...props}
      className={`${className} flex flex-col items-center`}>
      <ProfileImage url={profileImg.url}/>
      <div className="mt-4 mb-1">
        {children}
      </div>
    </ProfileCard>
  )
}

export default UserProfileCard

export const ProfileImage = ({size, url}) =>
  <StyledProfileImage size={size} style={{ backgroundImage: `url(${url})` }} />

const StyledProfileImage = styled.div`
  height: 190px;
  width: 145px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all ease-in 0.2s;
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.boxShadow['md']};
`

const ProfileCard = styled.a`
  width: ${p => p.autoWidth ? 'auto' : '270px'};
  cursor: pointer;
  &:hover {
    ${StyledProfileImage} {
      transform: translateY(-1px);
      box-shadow: ${theme.boxShadow['md']}, ${theme.boxShadow['2xl']};
    }
  }

  @media (min-width: ${theme.screens.sm}) {
    width: ${p => p.autoWidth ? 'auto' : '280px'};
  }
  @media (max-width: ${theme.screens.sm}) {
    display: flex;
    margin-bottom: ${theme.spacing[12]};
    align-items: center;
    
    ${StyledProfileImage} {
      margin-right: ${theme.spacing[4]};
    }
  }
`
