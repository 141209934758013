import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Ananda Mohan, Fellow IEEE",
  "designation": "FNAE, Technology Advisor, C-DAC, Bangalore",
  "shortDesc": "**P V Ananda Mohan** is with the Centre for Advanced Computing (C-DAC), Bangalore as Technology Advisor since 2014. He received the B.Sc and M.Sc (Tech.) degrees from Andhra University, India, in 1965 and 1968, respectively and the PhD degree in electrical communication engineering from Indian Institute of Science, Bangalore, in 1975. He has served I.T.I. Limited for nearly 30 years for R&D activities from 1973 till 2003 and later he was with R&D Division of Electronics Corporation of India Limited (ECIL), Bangalore. \n\nHis research interests are in the areas of VLSI design, VLSI architectures, Cryptography. He has published in these areas in refereed international journals and conferences. He has co-authored five books Switched Capacitor Filters:Theory, Analysis, and Design with Dr. M N S  Swamy and Dr. V. Ramachandran (Prentice-Hall, 1995) and authored four more books Residue Number Systems: Algorithms and Architectures (Kluwer Academic, 2002), Current-Mode VLSI Analog Filters: Design and Applications (Birkhauser, 2003), VLSI Analog Filters, Birkhauser 2012 and Residue Number Systems: Applications, Birkhauser 2016.  \n\nDr. Mohan is a Fellow of IEEE (U.S.A), IETE (India), National Academy of Engineering (FNAE). He is the Chair of IEEE CAS Chapter, Bangalore. He was the Associate Editor of IEEE TRANSACTIONS ON CIRCUITS AND SYSTEMS—I: REGULAR PAPERS during 2000–2003. He is an Associate Editor of the IETE Journal of Education and Circuits, Systems and Signal Processing (CSSP), Birkhauser.",
  "profileImg": "/img/ananda-mohan.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      