import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other/FDP",
  "title": "Faculty Development Initiative"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Faculty Development Initiative`}</h2>
    <p>{`Faculty development programs (FDP) play a very important role for the teachers of the institutions of higher learning. It is more important for the faculty members of engineering disciplines to update the teachers with the recent developments in the field of engineering and technology keeping in view the rapid changes that have been taking place and the consequent impact of such changes in the pedagogy and curriculum. Updating the teachers with the recent developments in the theory and with the necessary experiments relating to the newer technologies will help the institutions to offer certain subjects of higher relevance to the industry and enhance the employment scope of the students. The All India Council of Technical Education (AICTE) has different schemes in order to improve academic performance and to broaden the technology outlook of the faculties of engineering colleges. `}</p>
    <p><strong parentName="p">{`We at Sandhaan Labs would be very much interested to provide support in terms of expert lectures and experimental setup in the areas related to our research focus to offer FDP in collaboration with different academic institutes as per the prescription of AICTE.`}</strong></p>
    <p><strong parentName="p">{`Apart from FDP, we have the provision of organizing need-based research-oriented training programs at Sandhaan Labs, as well as, in other academic institutes.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      