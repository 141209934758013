import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/research-focus/HW",
  "title": "Hardware Acceleration"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hardware Acceleration`}</h2>
    <p>{`Any given product, for whatsoever might be the application, can have sustained popularity, only if it meets the performance requirements, it is cost-effective, and has a convenient size and form-factor. Apart from that, it must be power-efficient. We have several choices of hardware, software, and platforms to implement an application. If the application is not computation-intensive, one can implement it in a low-cost microcontroller. For computation-intensive applications, very often a high-end/high-speed processor may not be the right choice based on the cost and power-efficiency. In such cases, we may need to use a GPU or FPGA/ASIC unit along with an appropriate microcontroller or microprocessor. For real-time response, FPGA/ASIC could be the preferred choice. Particularly, when the volume of production is large and a small form-factor is required, one has to go for ASIC implementation. For widely used smart applications involving complex neural networks and other machine learning algorithms, dedicated ASIC/FPGA-based hardware acceleration is the desirable choice. To have an optimal implementation, one has to consider various design aspects, e.g, the basic throughput requirement, along with the cost, size, and power consumption. Accordingly, one has to select the processor and decide on the partition of the algorithm so that a more computation-intensive part is implemented by the dedicated hardware accelerator while control-oriented functionalities are implemented by the processor. `}</p>
    <p><strong parentName="p">{`We collaborate with an internationally renowned, strong group of researchers spread across Asia, Europe, and the USA to work on the design of dedicated hardware accelerators for different applications.`}</strong>{`     `}</p>
    <p>{`Some of the important aspects of hardware accelerator design which we undertake are as follows:`}</p>
    <p><strong parentName="p">{`Algorithm level transformation for hardware implementation`}</strong></p>
    <p><strong parentName="p">{`Parallel processing, and seamless pipeline strategy for power, performance, and area`}</strong></p>
    <p><strong parentName="p">{`Mapping algorithms to low-power and high-throughput hardware architecture`}</strong></p>
    <p><strong parentName="p">{`Arithmetic circuits and memory architecture for signal/image processing, communication, and cryptography`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      