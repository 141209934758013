import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other/Masters",
  "title": "Master's Projects"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Masters' Projects`}</h2>
    <p>{`Most universities and post-graduate institutes usually require the Masters’ level students to complete a minor research project and write a dissertation/thesis for partial fulfillment of the requirement of their degree.
We have a provision for the Masters’ research projects at Sandhaan Labs. The duration of those projects could be of six months to one year based on the requirement of the parent institution.  During the project work, we provide basic training to the students for conducting research, which will be useful in their career in the industry as well as higher studies. Every student is provided with a mentor for necessary academic support during the project. The training of the students during the Masters' project involves the followings:`}</p>
    <p>{`(a) To understand the basics of research methodology`}</p>
    <p>{`(b) To study the relevant literature and explore a topic of interest under expert guidance.`}</p>
    <p>{`(c) Thorough understanding of the topic of interest with the help of an expert mentor.`}</p>
    <p>{`(d) Technology innovation during the project: The student may develop an algorithm/approach and perform experiments to validate that.`}</p>
    <p>{`(e) Learn the approach to write the thesis and write the project dissertation/ thesis.`}</p>
    <p><strong parentName="p">{`Interested Masters' students may contact us at contact@sandhaanlabs.in for their Masters' thesis work`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      