import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other/conference-workshop",
  "title": "Conference and Workshop Organisation"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Conference and Workshop Organisation`}</h2>
    <p>{`Sandhaan Labs will be organizing conferences and workshops in the emerging areas of current industrial relevance and social importance in the virtual-mode as well as the physical-mode in post-COVID time.  We are interested to organize such events in collaboration with different academic institutes in India and abroad. `}</p>
    <p><strong parentName="p">{`We provide all technical support to the interested academic institutes for the organization of conferences and workshops.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      