import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/research-focus/data-science-analytics",
  "title": "Data Science and Analytics"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Data Science and Analytics`}</h2>
    <p>{`Not just the business processes, e-governance, and social media, but the extensive digitization of all walks of life has resulted in an enormous volume of data of different types. The rapid increase in deployment of the Internet of Things for a wide range of applications such as industrial, consumer, healthcare, logistics, and transportation, etc. has added billions of computing nodes which act as sources of data and continuously pour on lots of relevant data in the cloud. We find various types of data relating to sales, customer feedback, manufacturing, and distribution for business applications along with those of healthcare, human-resource planning & development, agriculture, and many more. Availability of a large volume of data is of great advantage since it can provide more reliable information. But on the other hand, such a large volume of data from various sources also possesses a great challenge to store the data in proper format and analyze that to derive useful insights. The recent development in the field of big-data which supports the processing of large data files; and artificial intelligence empowered by advanced machine learning techniques like deep learning algorithms has helped to derive actionable data and reliable information from the available data-sets and supports complex diagnostic and predictive analytics. Appropriate low-complexity techniques are also developed for descriptive and prescriptive analytics. Intelligent data analytics not only has got a widespread application in the business decision-making process, human resource planning, financial risk assessment, and risk mitigation, etc. Apart from that, it has found application in agriculture, healthcare, utilities, strategic planning, and administration.`}</p>
    <p>{`Our current research on data analytic applications is focused on:`}</p>
    <p><strong parentName="p">{`Data analytics for financial risk assessment and risk mitigation`}</strong></p>
    <p><strong parentName="p">{`Data analytics for decision support system`}</strong></p>
    <p><strong parentName="p">{`Augmented analytics`}</strong></p>
    <p><strong parentName="p">{`Data analytics for agriculture and healthcare applications`}</strong></p>
    <p><strong parentName="p">{`Data analytics for start-up guidance`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      