import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/research-focus/IoT",
  "title": " IoT, Smart Home and Smart City Applications"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`IoT, Smart Home, and Smart City Applications`}</h2>
    <p>{`The Internet of Things (IoT) is an extension of the conventional Internet where the connectivity is extended to any physical object or thing. The things in IoT are the end-points, which can be a small thing like a thermometer or a light bulb as well as big or too big things like toys, televisions,  trucks, or trains, or anything we can imagine. IoT has a wide range of applications relating to healthcare, agriculture, advanced manufacturing, transportation, smart home, and industrial automation, etc. The application perimeter of IoT is increasing unimaginably over time and going to be the driver of the next phase of the digital revolution which is going to affect every walk of human life. The advantages of IoT are, however, challenged by security threats. Along with its growing number of applications, the number of IoT devices is also going to be huge. Currently, there are more than 25 billion active IoT nodes, and the number is growing exponentially. On the other hand, a single compromised node can be turned out to be malicious and cause a disaster. A security breach in IoT could cause severe and irreversible damages to critical infrastructures and human lives even. Since the IoT nodes very often run round the clock, the overall energy consumption in the nodes is another important concern. Besides, the current trend in IoT is to bring a significant amount of data processing closer to the end-points in order to reduce the latency and bandwidth. Moreover, computing at the IoT edge helps in reducing data breaches and other security issues associated with data communication. Nowadays the smart home and smart city applications have become a subset of IoT applications. Our current research on this area is focused on the followings:`}</p>
    <p><strong parentName="p">{`IoT end-points and ecosystem security`}</strong>{` `}</p>
    <p><strong parentName="p">{`Power-efficient design and implementation of IoT edge-computing.`}</strong></p>
    <p><strong parentName="p">{`End-to-end ecosystem design for IoT applications`}</strong></p>
    <p><strong parentName="p">{`Operability and reliability of military, medical, and industrial IoT`}</strong></p>
    <p><strong parentName="p">{`Communication and IoT network architecture for smart home and smart city applications`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      