import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other-activities",
  "title": "Other Activities"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Faculty Development Initiative`}</p>
    <p>{`Post-Doctoral Research `}</p>
    <p>{`Doctoral Research `}</p>
    <p>{`Master's Projects`}</p>
    <p>{`Training and Internship`}</p>
    <p>{`Conference and Workshop Organisation`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      