import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other/training-internship",
  "title": "Training and Internship "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Training and Internships`}</h2>
    <p>{`The internship program is opened round the year in Sandhaan Labs. Duration of internship is considered in a flexible way (for a period of two months to six months) based on the time which a student can spend during his/her engineering course or at the end of the course. The engineering undergraduates, as wells as the students from management and students of BSc and MSc computer science also can participate in internships program at Sandhaan Labs.  One of our primary objectives of the internship is to let the interns explore the possible application of their knowledge gathered in the classrooms in order to solve a societal problem. Besides, we try to groom the aptitude for research in the mind of young students and develop the skill and ability to draw appropriate inferences from experimental results, which could be useful for a future career in corporate or academia. Moreover, we provide attention to improve the communication skill for technical interactions in the interns. The interns are generally provided with a mentor to provide the necessary support. Apart from that, all interns are exposed to brainstorming sessions on design and innovation with scientists, research students, and other interns. Outstanding interns can work for writing a research paper for submitting to a journal or conference.`}</p>
    <p>{`Apart from internships for students, we conduct training programs for students as well as teachers and industry professionals on different emerging areas of technology which is announced from time to time under news items on this website.`}</p>
    <p><strong parentName="p">{`Interested candidates for doing internships with us are encouraged to contact us at contact@sandhaanlabs.in.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      