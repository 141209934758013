import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Samarendra Dandapat",
  "designation": "Professor of Electrical and Electronics Engineering, IIT Guwahati",
  "shortDesc": "Dr. Samarendra Dandapat is a Professor in the Department of Electronics and Electrical Engineering (EEE), Indian Institute of Technology (IIT) Guwahati. He received his Ph.D. degree from IIT Kanpur, M. Tech. degree from IIT BHU and B. Tech. Degree from MNNIT Allahabad, India. \r\n\r\nHe has been serving IIT Guwahati since 1997 as a faculty member. He was Head of the Department of EEE from 2005 to 2006, Dean Students Affairs from 2006 to 2008 and Dean Administration from 2009 to 2012 at IIT Guwahati. He was also the founder Dean of IIT Patna from 2008 to 2009. He was a visiting research fellow in Nanyang Technological University (NTU), Singapore from 2003 to 2004. He has served as member of BOG of IIT Guwahati, NIT Silchar, NERIST Itanagar and Regional Film and Television Institute Guwahati. \r\n\r\nHis research interests include Cardiovascular Signal Processing, Stressed Speech Processing and Fundus Image Processing. He has published around 70 journal papers and 120 conference papers. Prof. Dandapat has supervised 15 Ph. D. Thesis and 50 M. Tech. Thesis works. ",
  "profileImg": "/img/photo-dandapat-.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      