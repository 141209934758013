import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Ashwini Nanda, Fellow IEEE",
  "designation": "IBM Supercomputer Pioneer, High-Performance Computer Architect, and Founder & CEO of HPC Links Pvt Ltd",
  "shortDesc": "Dr Ashwini K. Nanda is an internationally recognized expert in high performance computer architecture and applications. He is the Founder and CEO of HPC Links Pvt Ltd, based out of India and US. Dr Nanda pioneered the concept of heterogeneous supercomputing at IBM TJ Watson Research Center in NY. The technology developed by his team enabled the world’s first PetaFLOP supercomputer system ‘Roadrunner’ at Los Alamos National Labs. As the Head of Tata Computational Research Labs, Pune, he led the development of the Eka system that became Asia's fastest supercomputer in 2007-2009. As a young engineer Dr Nanda had worked with honourable ex-President Dr Abdul Kalam in 1980’s to develop parallel computers for India's Akash missile system at Wipro, Bangalore. He has served in the Editorial Board of several internationally reputed journals such as IEEE Transactions on Parallel and Distributed Systems, special issues of the IEEE Computer magazine and IBM Journal of R&D. He holds fifteen US patents and has published over 60 papers in the area of parallel computer systems and scientific applications. He is currently an adjunct professor at IIT Bhubaneswar. Dr. Nanda is a Fellow of the Institute of Electrical and Electronic Engineers (IEEE), USA. ",
  "profileImg": "/img/nanda.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      