import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Banshidhar Majhi",
  "designation": "Director, IIIT Design and Manufacturing, Kancheepuram, Chennai",
  "shortDesc": "Prof. Banshidhar Majhi is presently serving as the Director, IIITDM Kancheepuram, Chennai a centrally funded institute of national importance since July 2017 on lien from NIT Rourkela. He has three years of industry experience and more than 30 years of teaching and research experience in the field of Computer Science and Engineering. In addition to his illustrious teaching and research experience, he has served in various administrative positions as HOD, Dean (Academic), Chairman - Automation Cell, and contributed innovative solutions for the growth of the Institute.  \r\n\r\nHis research interests include Computer Vision, Machine Learning, Cryptography Protocols, and IoT. He has guided 20 scholars for Ph. D. and 8 scholars for MS by Research, in addition to more than 150 B.Tech. and M. Tech. students. He has 90 research publications in peer-reviewed journals and more than 150 publications in conferences of repute. He is a Senior Member IEEE, Fellow IETE, Fellow IE (India), Life Member of Computer Society of India. For his outstanding contributions in Engineering and Technology, the Government of Odisha has conferred on him “Samanta Chandra Sekhar” award in 2016. Prof. Majhi puts his best effort in whatever he does and believes in the quote “Efforts never Fail”.",
  "profileImg": "/img/b-majhi-photo.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      