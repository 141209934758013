import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/research-focus/security",
  "title": "Cyber Security & Risk Mitigation"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Cyber Security & Risk Mitigation`}</h2>
    <p>{`The world is moving very fast towards the digitization of every sphere of life. The widespread adoption of Industry 4.0, the digital transformation of business processes, the popularity of e-commerce and e-governance, and the increasing deployment of smart devices as a whole have resulted in an extensive proliferation of connected devices and systems. Accordingly, a large volume of sensitive and private data is also generated, transmitted across the networks, and stored in the cloud as well as local storage. Many of the connected end-points, such as those pertaining to IoT applications are very often resource-constrained and not well-equipped with security features, which significantly increase the security vulnerability. The attack surface has been expanding and the number of attack vectors is increasing. The number of cyber-attacks in the form of denial of service (DoS), distributed denial of service (DDoS), phishing, hacking, insider threat, and ransomware, etc. are on the rapid rise over the years. Implementation of security measures to safeguard the confidentiality, integrity, and availability of data in connected digital systems and the data moving across the networks has been an overwhelming concern. Security needs to be an evolving process to develop technologies to protect the connected digital systems, programs, data, and communication network against the evolving threats and increasing vulnerabilities.`}</p>
    <p>{`Currently, we focus on the design and implementation of proactive measures like prevention, protection, and detection to handle the cyber-attacks and threats. We work on the following areas of research on cybersecurity in collaboration with researchers from India, Taiwan, and Singapore.`}</p>
    <p><strong parentName="p">{`Development of cryptographic algorithms and implementation `}</strong></p>
    <p><strong parentName="p">{`Light-weight cryptography and authenticated encryption`}</strong></p>
    <p><strong parentName="p">{`Design time security and hardware-based security`}</strong></p>
    <p><strong parentName="p">{`Real-time malware detection and techniques to cure`}</strong></p>
    <p><strong parentName="p">{`Security analytics and intrusion detection system`}</strong></p>
    <p><strong parentName="p">{`Identity, privacy, and trust management for end-point security`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      