import styled from 'styled-components'

export const ContentBock = styled.div`
  h1 {
    line-height: 1.2em;
    margin-bottom: 1.0em;
    margin-top: 1.5em;
  }
  h2 {
    line-height: 1.2em;
    margin-bottom: 0.8em;
    margin-top: 1.2em;
  }
  h3 {
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: 1.0em;
  }
  h4 {
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  h1, h2, h3, h4 {
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
`
