import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Javier Valls",
  "designation": "Professor of  Electronics, Universitat Politecnica de Valencia",
  "shortDesc": "Javier Valls received the telecommunication\r engineering degree from the Universidad Politecnica\r de Catalunya, Spain, and the Ph.D. degree in\r telecommunication engineering from the Universitat\r Politecnica de Valencia, Spain, in 1993 and 1999,\r respectively. He has been with the Department of\r Electronics at the Universitat Politecnica de Valencia\r since 1993, where he currently is a Professor. His\r current research interests include the design of\r \r\nFPGA-based systems, computer arithmetic, VLSI\r signal processing, and digital communications.",
  "profileImg": "/img/javier.png"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      