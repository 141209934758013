import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Vimal Bhatia",
  "designation": "Professor of Electrical Engineering  Indian Institute of Technology, Indore,",
  "shortDesc": "Vimal Bhatia (SM’12) is currently working as a Professor with the Indian Institute of Technology (IIT) Indore, India, and is an adjunct faculty at IIT Delhi and IIIT Delhi, India. He received Ph.D. degree from the Institute for Digital Communications from The University of Edinburgh, Edinburgh, U.K., in 2005. During Ph.D. he also received the IEE fellowship for collaborative research at the  Department of Systems and Computer Engineering, Carleton University, Canada, and is a Young Faculty Research Fellow from MeitY, Govt of India. He is also a recipient of Prof SVC Aiya Memorial Award (2019). He has worked with various IT companies for over 11 years both in India and the UK. He is a PI/co-PI/coordinator for external projects with funding of over USD 17 million from MeitY, DST, UKIERI, MoE, AKA, IUSSTF, and KPMG. He has more than 270 peer-reviewed publications and has filed 13 patents. His research interests are in the broader areas of communications, non-Gaussian non-parametric signal processing, machine/deep learning with applications to communications and photonics. He is a reviewer for IEEE, Elsevier, Wiley, Springer, and IET. He is currently a Senior Member of IEEE, Fellow IETE, and certified SCRUM Master. He was also the General Co-Chair for IEEE ANTS 2018, and General Vice-Chair for IEEE ANTS 2017. He has served as founder head of Center for Innovation and Entrepreneurship, Associate Dean R&D and Dean, Academic Affairs. He has delivered many talks, tutorials and conducted faculty development programs for the World Bank’s NPIU TEQIP-III programs. He is currently Associate Editor for IETE Technical Review, Frontiers in Communications and Networks, and IEEE Wireless Communications Letters.",
  "profileImg": "/img/photo-vimal.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      