import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Maher Jridi",
  "designation": "Professor and Head of IoT, Vision, and Data Analytics, ISEN, Brest/Nantes, France",
  "shortDesc": "Maher Jridi received the Engineering (Hons.) degree from the Graduate Telecommunication School Sup’Com, Tunis, Tunisia, in 2003 and the Ph.D. (Hons.) degree in electronics from the IMS Laboratory, University of Bordeaux I, Bordeaux, France, in 2007. He was involved in digital calibration for interleaved data converters. He has received from University of occidental Britany in 2018, the qualification for full professorship in the field of digital electronics, information processing, and telecommunications. He is currently Professor with “Institut Supérieur de l’Électronique et du Numérique”, Brest/Nantes France. In terms of responsibilities, Maher JRIDI is a Head of IoT Master degree specialization at ISEN and the Head of Vision and Data Analysis Team of Lab ISEN. The team is composed of 8 Associate professors, 2 postdoctrants and 9 PhD students. He has authored around 50 reputed journal articles or conference papers. His research interests include very large-scale integration design for signal/image/video processing, particularly in algorithm architecture co-design for resource-constrained systems. Recently, research scope includes, low-cost video processing, efficient CNN for image classification and segmentation and advanced IoT architectures.",
  "profileImg": "/img/maher.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      