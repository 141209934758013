import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/research-focus/ai-ml",
  "title": "AI and Machine Learning"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Artificial Intelligence and Machine Learning`}</h2>
    <p>{`Artificial Intelligence (AI) empowers computers with some kind of intelligence to recognize, detect, and understand like a human being. It enables digital computers to have human-like reasoning, adapting to situations, and learning from experiences. Various machine learning (ML) algorithms have been developed over the years which makes the field of AI more useful and reliable. The recent development of `}<strong parentName="p">{`deep learning`}</strong>{` (DL) techniques like `}<strong parentName="p">{`deep neural networks`}</strong>{` (DNN), `}<strong parentName="p">{`recurrent neural networks`}</strong>{` (RNN), and `}<strong parentName="p">{`convolutional neural networks`}</strong>{` (CNN) has revolutionized the scope and improved the accuracy of assertions of machine learning and artificial intelligence as a whole.`}</p>
    <p>{`AI and ML have gained unprecedentedly high popularity in recent years due to their applications in almost all areas of human lives from smart toys to unmanned aerial vehicles. It is difficult to imagine any specific area where the AI and ML techniques are not used. Pattern recognition, natural language processing, object recognition and detection, medical diagnostics, scene understanding, event-based response generation, and intelligent cyber-defense are some key areas where AI and ML have done great break-through in recent times.`}</p>
    <p>{`Advanced and low-complexity AI and ML techniques continue to be developed for health-care and medical instrumentation, agriculture, aerospace, military, education, intelligent transportation, automotive, smart-home and smart-city, forecasting and predictions, industrial-safety, process control, and advanced manufacturing, preventive maintenance, strategic planning, risk management, and decision support systems, etc.`}</p>
    <p><strong parentName="p">{`A strong group of more than 30 domain experts from reputed academic institutions in India and abroad who currently work on the above areas, and have made significant contributions to AI and ML algorithms and applications is associated with us to provide research guidance to the PhD and post-doctoral researchers for high-quality publications and intellectual property development.`}</strong></p>
    <p><strong parentName="p">{`Development of AI and ML algorithms and their efficient implementation is one of our core research areas, and we encourage PhD and postdoctoral candidates to work on AI and ML at Sandhaan Labs to unleash its enormous potential for the use of humanity.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      