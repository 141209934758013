import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other/PDR",
  "title": "Post-Doctoral Research "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Post-Doctoral Research Support`}</h2>
    <p>{`Professionals from the industry as well as academia can pursue post-doctoral research at Sandhaan Labs for more in-depth study in their previous/current area of research or in a newly emerging area of research for a certain period in order to improve their career and broaden their theoretical understanding and strengthen their experimental skill. `}</p>
    <p>{`Sundhaan Labs will assign a mentor to every post-doctoral researcher, and he/she may work together with some expert collaborators from India or abroad. We have collaboration with more than 100 expert researchers from reputed institutes in India and some foreign countries. Post-doctoral researchers can publish papers jointly with expert mentors and collaborators. `}</p>
    <p><strong parentName="p">{`The scope of different categories of post-doctoral research is outlined in the following:`}</strong></p>
    <p>{`(a) Full-time postdoctoral research at Sandhaan Labs, Bhubaneswar, India. Currently, we do not have any extension of laboratories outside Bhubaneswar. There is a possibility of a few fellowships for post-doctoral research.`}</p>
    <p>{`(b) Postdoctoral researchers can pursue the research at their current location. The candidates may do the research work outside their office hours without taking leave from their current position.`}</p>
    <p>{`(c) Remote post-doctoral research without fellowship.`}</p>
    <p><strong parentName="p">{`Interested candidates for post-doctoral research are encouraged to contact us at contact@sandhaanlabs.in.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      