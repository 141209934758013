import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/other/PhD",
  "title": "Doctoral Research"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Doctoral Research Support`}</h2>
    <p><strong parentName="p">{`Sandhaan Labs`}</strong>{` which is primarily an R&D organization has a strong interest to improve the quality of research of doctoral candidates. We offer some specific need-based programs for PhD candidates. If someone has a passion for research can do a PhD either as a full-time researcher or on a part-time basis while continuing in their current job. Sometimes, it is difficult for PhD students to get a suitable supervisor available nearby, and sometimes there is a lack of laboratory facilities. At `}<strong parentName="p">{`Sandhaan Labs`}</strong>{` we help such doctoral candidates in providing the necessary research support. When a student requires expert research guidance in any of our focus areas of research, we can associate the student and his/her supervisor with a suitable research expert with the consent of the supervisor is interested in that. Whenever required, we can provide laboratory facilities in some limited areas of research and otherwise can help to find a suitable laboratory in some other organization in India or abroad for remote access. It is also observed that doctoral students need proper guidance to write good research papers. We shall be conducting virtual workshops on a regular basis to train the doctoral students for improving the skill of writing high-quality papers.`}</p>
    <p><strong parentName="p">{`Sandhaan Labs is interested to collaborate at the institute or university level on our doctoral support program.`}</strong></p>
    <p><strong parentName="p">{`Interested candidates for PhD level research are encouraged to contact us at contact@sandhaanlabs.in.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      