import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Thambipillai Srikanthan",
  "designation": "Professor of Computer Science & Engineering and Director of Cyber Security Research Centre, NTU, Singapore",
  "shortDesc": "Professor Srikanthan is a full professor at Nanyang Technological University (NTU) and holds a concurrent appointment as the Executive Director of the university level Cyber Security Research Centre from 1 Feb 2016. Prior to this, he served as Chair of Computer Science and Engineering School (SCSE) for 6 years (2010-2016).  As Chair of SCSE, he was responsible for 1800 students (UG and MSc), 80 faculty, 60 non-faculty, 180 research staff and 360 PhD students. He also holds a concurrent position as the Program Director of the TUM-CREATE program that attracted S$46m from NRF in April 2016. \r\n\r\nHis research interests include vulnerability analysis, security by design, hardware security, design methodologies for high-productivity embedded computing systems, architectural translations of compute-intensive algorithms and high-speed techniques for vision- enabled systems. Dr Srikanthan has successfully graduated 36 graduate students (18 PhDs and 19 Masters by Research) and has published about 465 technical papers including 123 papers in IEEE Transactions, IEE proceedings and other reputed international journals. \r\n\r\nIn 2006, he was awarded Public Service Public Administration Medal by the government of Singapore for outstanding contributions to education and research in Singapore. Prof Srikanthan was also the co-founder of \"Quantum Inventions\" and \"Scantist\", a cybersecurity start-up focusing on vulnerability assessment.",
  "profileImg": "/img/srikanthan-photo.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      