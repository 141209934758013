import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/publications",
  "title": "Publications"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Recent Publications`}</h2>
    <ol>
      <li parentName="ol">{`S. M. Cho, `}<strong parentName="li">{`P. K. Meher`}</strong>{`, L. T. N.Trung, H. J. Cho, and S. Y. Park, 'Design of Very High-Speed Pipeline FIR Filter Through Precise Critical Path Analysis', Accepted for publication in `}<span style={{
          "color": "blue"
        }}>{` `}<em parentName="li">{` IEEE Access`}</em></span>{`, `}<strong parentName="li">{`Impact Factor: 3.745`}</strong>{` `}<a parentName="li" {...{
          "href": "https://ieeexplore.ieee.org/document/9361650"
        }}>{`PDF LINK`}</a>{`.`}</li>
    </ol>
    <p>&nbsp;</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`P. Mishra, A. Banerjee, M. Ghosh, S. Gogoi, and `}<strong parentName="li">{`P. K. Meher`}</strong>{`, 'Implementation and Validation of Quadral-Duty Digital PWM to Develop a Cost-optimized ASIC for BLDC Motor Drive', Accepted for publication in `}<span style={{
          "color": "blue"
        }}>{` `}<em parentName="li">{` Elsevier Journal of Control Engineering Practice`}</em></span>{`, `}<strong parentName="li">{`Impact Factor: 3.193`}</strong>{`. `}</li>
    </ol>
    <p>&nbsp;</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`P. K. Meher`}</strong>{`, S-K. Lam, T. Srikanthan, D. H. Kim, S. Y. Park, 'Area-Time Efficient 2-Dimensional Reconfigurable Integer DCT Architecture for HEVC', `}<span style={{
          "color": "blue"
        }}>{` `}<em parentName="li">{` Special Issue on System-on-Chip (SoC) Design and Its Applications, MDPI Journal of Electronics`}</em></span>{`, DOI: 10.3390/electronics10050603, `}<strong parentName="li">{`Impact Factor 2.412`}</strong>{`.`}</li>
    </ol>
    <h2>{`Previous Publications`}</h2>
    <p>{`There are several outstanding publications of the founder of Sandhaan Labs in collaboration with researchers from different parts of the world before the inception of Sandhaan Labs. `}<a parentName="p" {...{
        "href": "https://sandhaanlabs.in/usermedia/pkmeher/publications.pdf"
      }}>{`A list of publications of the founder is available at the following link`}</a>{` `}<a parentName="p" {...{
        "href": "https://sandhaanlabs.in/usermedia/pkmeher/publications.pdf"
      }}>{`https://sandhaanlabs.in/usermedia/pkmeher/publications.pdf`}</a>{`.`}</p>
    <p>&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      