import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "G Panda, FNAE, FNASc",
  "designation": "Former Professor of Electrical Sciences, Deputy Director, IIT Bhubaneswar, and  Director NIT, Jamshedpur",
  "shortDesc": "Professor Ganapati Panda has served as the Deputy Director of Indian Institute of Technology (IIT), Bhubaneswar and the Director of National Institute of Technology (NIT), Jamshedpur. He has worked for 47 years in teaching and research in leading technical institutions like NIT and IIT in India. He is well-known as a strong researcher and great teacher.  \n\nHe did his Ph. D. (1981) from IIT, Kharagpur in Digital Signal Processing and  Post-Doctoral research at the University of Edinburgh, UK (1984-86). He has guided 42 Ph.Ds; and several PhD students are continuing their PhD work under his active guidance in the field of Signal Processing, Communication, AI and Machine Learning. He has published about 400 research papers in various referred Journals and Conferences with more than 10000 citations and h-index more than 50.  He has successfully completed many research projects from various funding agencies in India and abroad. \n\nHe is a Fellow of the National Academy of Engineering, India (FNAE) and Fellow of National Academy of Science, India (FNASc) for his significant research contribution to signal processing and machine learning. He has received Samanta Chandra Sekhar Award (1993), Biju Patnaik Award (2012), INAE- Outstanding Teacher Award (2017), AICTE-INAE Distinguished Visiting Professorship (2019) and AICTE- Margadarshak (2019) for significant contribution to engineering, education and research. He is a Senior Member of IEEE, Fellow of IET, Fellow of IETE, Fellow of IE, Life Member of CSI, Life Member of ISTE and Life Member of System Society. He has chaired and delivered keynote addresses in many international conferences in India and abroad. His research interests are in the areas of Artificial Intelligence, Machine Learning, Digital Signal Processing, Digital Communication and Intelligent Instrumentation.",
  "profileImg": "/img/member-profile-g-panda.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      