import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "contentPage",
  "path": "/research-focus/NLP-CV",
  "title": "NLP and Computer Vision Applications"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`NLP and Computer Vision Applications`}</h2>
    <p>{`Natural language processing (NLP) is going to make a very big impact with several exciting applications using advanced machine learning techniques along with conventional rule-based algorithms by providing more accurate analysis using relatively less training data. The hybrid approach using a combination of supervised and unsupervised learning, supported by reinforcement learning and deep learning models has shown significant improvement in accuracy and reliability in many different applications. The domains of applications of NLP has been expanding fast. It is gaining popularity in speech-controlled automation, education, healthcare, and in a big way in business applications.`}</p>
    <p>{`Computer vision is another such technology area which is opening up many applications with its ability to extract relevant information from individual images, videos, and image sequence to make the computers understand whatever a camera does capture. Generation of smart data through intelligent computer vision techniques will have many derivative use-cases in the military, industrial, retail, automotive, healthcare, agriculture, home security, mobility, and many more.  `}</p>
    <p>{`Although the work on NLP and computer vision falls within the broad category of application
of artificial intelligence, we take it as a separate area of research focus due to its importance and scope of useful exploration. Moreover, the end-to-end system implementation of NLP and computer vision applications has always been a challenging task and needs an in-depth study of the methods of realization.`}</p>
    <p>{`We have a strong interest in the following fields of NLP and computer vision research.`}</p>
    <p><strong parentName="p">{`Algorithm design and exploration for emerging NLP applications`}</strong></p>
    <p><strong parentName="p">{`Intelligent algorithms for computer vision applications`}</strong></p>
    <p><strong parentName="p">{`System-level design and realization of NLP and computer vision applications`}</strong></p>
    <p><strong parentName="p">{`Real-time smart surveillance and smart-data generation`}</strong></p>
    <p><strong parentName="p">{`Computer vision for military, healthcare, and industrial applications`}</strong></p>
    <p><strong parentName="p">{`NLP and computer vision for aging population support`}</strong></p>
    <p><strong parentName="p">{`Cost-effective and reliable implementation of NLP and computer vision`}</strong>{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      