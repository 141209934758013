import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "contentType": "members",
  "name": "Professor S K Nandy",
  "designation": "Dept of Computational & Data Sciences, Indian Institute of Science, Bangalore",
  "shortDesc": "Professor S K Nandy holds the position of a Senior Professor in the Department of Computational and Data Sciences, Indian Institute of Science, Bangalore. He graduated from I.I.T. Kharagpur in 1974 with a B.Sc. (Honors) degree in Physics, and then obtained his B.E. (Honors) degree in Electronics and Communication Engineering in 1980, M.Sc.(Engg.) degree in Computer Science and Engineering in 1986, and Ph.D. degree in Computer Science and Engineering in 1989, all from the Indian Institute of Science, Bangalore. \r\n\r\nOver the years, he has graduated 24 Phds, 22 MSc.(Engg.)  and over 100 Master’s students who have worked for their theses in the laboratory. His current research addresses important issues in micro-architectural and compiler optimizations for power and performance in Chip Multiprocessors (CMPs) and Runtime Reconfigurable System on Chips (MP-SoCs).  All his research target massively parallel architectures/platforms for accelerating computations for Next Generation Sequence Alignment, Numerical Linear Algebra, Real-time Face Recognition, Cognition Engines, and Molecular Dynamics. Prof. Nandy has over 175 research publications in International Journals and Proceedings of International Conferences that highlight his research contributions, and 5 patents to his credit. \r\n\r\nProf. Nandy is the founder of a technology startup company Morphing Machines, Pvt. Ltd which is engaged in design and development of high-performance reconfigurable silicon cores, in partnership with the Indian Institute of Science. He holds the position of Honorary Chief Scientist at Morphing Machines.  He is also the co-founder  of ReneLife Private Limited, launched under the faculty entrepreneurship program of the Indian Institute of Science. ReneLife is involved in developing and deploying HPC accelerators for Genome Informatics. ",
  "profileImg": "/img/nandy.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      